<template>
  <v-container fluid class="pa-6">
    <div>
      <v-data-table
        v-if="alerts"
        :headers="tableHeaders"
        :items="filteredAlerts"
        item-key="id"
        :items-per-page="-1"
        class="elevation-1 pointer"
        :loading="loading"
        :search="search"
        fixed-header
        height="calc(100vh - 273px)"
        :footer-props="{
          itemsPerPageText: $t('rowCount'),
          pageText: '{0}-{1} ' + $t('of') + ' {2}',
          itemsPerPageAllText: $t('all'),
        }"
        @click:row="goToAlertDetails"
      >
        <template #top>
          <v-card-title class="default-cursor">
            {{ $t('alertLog.alertLogHeader') }}
            <v-spacer />
            <v-switch v-model="onlyLiveAlerts" :label="$t('alertLog.hideSimulatedAlerts')" />
            <v-spacer />
            <v-text-field
              v-model="search"
              :label="$t('alertLog.search')"
              append-icon="fal fa-search"
            />
          </v-card-title>
        </template>
        <template #[`item.start`]="{ item }">
          <span>
            {{ $d(new Date(item.start), 'shortDate') }} {{ $t('timePrefix') }}
            {{ $d(new Date(item.start), 'time') }}</span
          >
        </template>
        <template #[`item.submissionMode`]="{ item }">
          <div v-if="item.submissionMode === 1" class="statusBadge statusLive">
            {{ $t('alertLog.live') }}
          </div>
          <div v-else class="statusBadge statusSimulate">
            {{ $t('alertLog.simulated') }}
          </div>
        </template>
        <template #[`item.isRecurring`]="{ item }">
          <span>{{ getState(item) }}</span>
          <v-icon v-if="item.isRecurring" class="recurrence-icon">fal fa-repeat</v-icon>
        </template>
        <template #[`item.estimatedSubscriberCount`]="{ item }">
          <v-icon v-if="item.includeListId" class="include-list-icon">fal fa-address-book</v-icon>
          <span>{{ item.estimatedSubscriberCount | separator }}</span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
export default {
  name: 'AlertLog',
  data() {
    return {
      search: '',
      center: {
        lat: 0,
        lng: 0,
      },
      loading: true,
      onlyLiveAlerts: false,
      filteredAlerts: [],
    };
  },
  computed: {
    alerts() {
      return this.$store.state.alertLog.alerts;
    },
    tableHeaders() {
      return [
        {
          text: this.$t('alertLog.start'),
          align: 'start',
          sortable: true,
          value: 'start',
        },

        { text: this.$t('alertLog.sendingMode'), value: 'submissionMode' },
        { text: this.$t('alertLog.state'), value: 'isRecurring', align: 'start' },
        {
          text: this.$t('alertLog.alertName'),
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: this.$t('alertLog.createdBy'), value: 'createdByUsername', hide: 'smAndDown' },
        // { text: 'Telenor', value: 'telenorId', align:'center', sortable: false, hide: 'smAndDown' },
        // { text: 'Telia', value: 'teliaId', align:'center', sortable: false,  hide: 'smAndDown' },
        {
          text: this.$t('alertLog.estimatedRecipients'),
          value: 'estimatedSubscriberCount',
          align: 'end',
        },
      ].filter((h) => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    },
  },
  watch: {
    alerts() {
      this.filteredAlerts = this.alerts;
    },
    onlyLiveAlerts(value) {
      if (value === true) {
        this.filteredAlerts = [];
        this.alerts.forEach((element) => {
          if (element.submissionMode == 1) {
            this.filteredAlerts.push(element);
          }
        });
      } else {
        this.filteredAlerts = this.alerts;
      }
    },
  },
  mounted() {
    this.$store.dispatch('alertLog/getAlertLog');
    this.loading = false;
  },
  methods: {
    moment: moment,
    goToAlertDetails(alert) {
      this.$router.push({ name: 'AlertDetails', params: { alertId: alert.id.toString() } });
    },
    getState(alert) {
      if (alert.isCancelled) {
        return this.$t('alertLog.cancelled');
      }
      if (moment(alert.start).isAfter(moment())) {
        return this.$t('alertLog.planned');
      }
      if (alert.finished) {
        return this.$t('alertLog.finished');
      }
      if (moment(alert.start).isBefore(moment()) && !alert.isRecurring) {
        return this.$t('alertLog.sent');
      }
      if (alert.isRecurring && moment(alert.start) < moment() && moment(alert.end) > moment()) {
        return this.$t('alertLog.active');
      }
      if (moment(alert.end) < moment()) {
        return this.$t('alertLog.completed');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info-card {
  padding: 16px;
  background-color: #dbe6e8; // #e6d9bd;
  color: #457b9d;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #88888861;
  text-align: left;
  a {
    text-decoration: none;
    color: #457b9d;
  }
  p {
    margin-bottom: 0;
  }
}
.statusBadge {
  // width: 60px;
  text-align: center;
  border-radius: 15px;
  max-width: 100px;
  min-width: 75px;

  &.statusLive {
    background-color: #f7cccc;
    border: 1px solid #ef9a9a;
    color: #670303;
  }

  &.statusSimulate {
    background-color: #ffdfb8;
    border: 1px solid #ffc175;
    color: #703e00;
  }
}
.recurrence-icon {
  font-size: 14px;
  margin-left: 8px;
  color: rgb(32, 32, 32);
}
.include-list-icon {
  font-size: 16px;
  margin-right: 5px;
  margin-top: -3px;
  color: rgb(32, 32, 32);
}
</style>
