var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('div',[(_vm.alerts)?_c('v-data-table',{staticClass:"elevation-1 pointer",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredAlerts,"item-key":"id","items-per-page":-1,"loading":_vm.loading,"search":_vm.search,"fixed-header":"","height":"calc(100vh - 273px)","footer-props":{
        itemsPerPageText: _vm.$t('rowCount'),
        pageText: '{0}-{1} ' + _vm.$t('of') + ' {2}',
        itemsPerPageAllText: _vm.$t('all'),
      }},on:{"click:row":_vm.goToAlertDetails},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{staticClass:"default-cursor"},[_vm._v(" "+_vm._s(_vm.$t('alertLog.alertLogHeader'))+" "),_c('v-spacer'),_c('v-switch',{attrs:{"label":_vm.$t('alertLog.hideSimulatedAlerts')},model:{value:(_vm.onlyLiveAlerts),callback:function ($$v) {_vm.onlyLiveAlerts=$$v},expression:"onlyLiveAlerts"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":_vm.$t('alertLog.search'),"append-icon":"fal fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.start",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.start), 'shortDate'))+" "+_vm._s(_vm.$t('timePrefix'))+" "+_vm._s(_vm.$d(new Date(item.start), 'time')))])]}},{key:"item.submissionMode",fn:function(ref){
      var item = ref.item;
return [(item.submissionMode === 1)?_c('div',{staticClass:"statusBadge statusLive"},[_vm._v(" "+_vm._s(_vm.$t('alertLog.live'))+" ")]):_c('div',{staticClass:"statusBadge statusSimulate"},[_vm._v(" "+_vm._s(_vm.$t('alertLog.simulated'))+" ")])]}},{key:"item.isRecurring",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getState(item)))]),(item.isRecurring)?_c('v-icon',{staticClass:"recurrence-icon"},[_vm._v("fal fa-repeat")]):_vm._e()]}},{key:"item.estimatedSubscriberCount",fn:function(ref){
      var item = ref.item;
return [(item.includeListId)?_c('v-icon',{staticClass:"include-list-icon"},[_vm._v("fal fa-address-book")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("separator")(item.estimatedSubscriberCount)))])]}}],null,true)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }